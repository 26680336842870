import React from 'react';
import pt from 'prop-types';
import { format } from 'date-fns';
import Text from '../../../../ui/text';
import Image, { imageProptypes } from '../../../../ui/image';
import SocialShare from '../../../../ui/socialShare';
import Container from '../../../../../modules/container';
import { getPressReleaseLink } from '../../../../../helpers/getPressReleaseLink';
import * as styles from './styles.module.scss';
import { getArticleType } from '../../../../../helpers/articleType';

const PressReleasedHeader = ({
  articleType,
  createdAt,
  headline,
  subline,
  image,
  slug,
}) => {
  const createdDate = format(new Date(createdAt), 'd MMM yyyy');

  return (
    <Container fluid small hasWhiteBg innerClass={styles.header}>
      <div className={styles.metaWrapper}>
        <div>
          <Text
            as="div"
            type="infotext"
            variant="condensed"
            className={styles.date}
          >
            {createdDate}
          </Text>
          {articleType && (
            <Text as="span" type="articleType">
              {
                // backwards compatibility for old contentful entries
                getArticleType(articleType)
              }
            </Text>
          )}
        </div>
        <SocialShare
          shareURL={`${process.env.GATSBY_DOMAIN}/${getPressReleaseLink(slug)}`}
        />
      </div>
      <div className={styles.textWrapper}>
        {headline && (
          <Text as="h1" type="h1">
            {headline}
          </Text>
        )}
        {subline && (
          <Text as="h2" type="h3" variant="semibold">
            {subline?.subline ? subline.subline : subline}
          </Text>
        )}
      </div>
      {image && (
        <div className={styles.imageWrapper}>
          <Image {...image} className={styles.img} />
        </div>
      )}
    </Container>
  );
};

PressReleasedHeader.prototype = {
  articleType: pt.string,
  createdAt: pt.string,
  headline: pt.string,
  subline: pt.string,
  image: pt.shape(imageProptypes),
  slug: pt.string.isRequired,
};

PressReleasedHeader.defaultProps = {
  articleType: '',
  createdAt: '',
  headline: '',
  subline: '',
  image: null,
};

export default PressReleasedHeader;
