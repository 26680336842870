import React from 'react';
import PressReleasedDetail from '../../components/newsroom/pressRelease';
import Layout from '../../modules/layout';

const PressReleasePage = ({ pageContext }) => {
  const { seoTitle, seoDescription, previewImage, slug } = pageContext;
  return (
    <Layout
      title={seoTitle}
      description={seoDescription}
      previewImage={
        pageContext.image?.image?.file?.url || previewImage?.file?.url
      }
      slug={slug}
    >
      <PressReleasedDetail data={pageContext} />
    </Layout>
  );
};

export default PressReleasePage;
