import React from 'react';
import Container from '../../../modules/container';
import RichText from '../../ui/richText';
import PressReleasedHeader from './components/pressHeader';
import CMSDownload from '../../cms-modules/download';
import CMSSeparator from '../../cms-modules/separator';
import CMSTextVisual from '../../cms-modules/textVisual';
import CMSContact from '../../cms-modules/contact';
import * as styles from './styles.module.scss';

const PressReleasedDetail = ({ data }) => (
  <>
    <PressReleasedHeader
      articleType={data.type}
      createdAt={data.date || new Date()}
      headline={data.headline}
      subline={data.subline}
      image={data.image}
      slug={data.slug}
    />
    <Container fluid small hasWhiteBg>
      {data.text && <RichText content={data.text.text} />}
      {data.download && (
        <CMSDownload {...data.download} className={styles.downloadWrapper} />
      )}
    </Container>
    {data.separator && <CMSSeparator {...data.separator} />}
    {data.textVisual && <CMSTextVisual {...data.textVisual} />}
    {data.contact && <CMSContact {...data.contact} />}
  </>
);

export default PressReleasedDetail;
